import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  LinkArtist,
  LinkWork,
  LinkType,
  OverlayImage,
  LinkIcon,
} from "./links";
import { motion, useInView, useAnimation } from "framer-motion";

import slugify from "slugify";

export const WorkGridItem = ({ work, slug, classes, isMedium, location }) => {
  const { title, year, mediums, artist } = work;
  const workHerolImage = getImage(
    work.heroImage.imageFile.localFile.childImageSharp
  );
  // console.log(
  //   slugify(work.title, {
  //     lower: true,
  //     remove: /[*+~.()'"!:@]/g,
  //   })
  // );
  // Animation inView
  // IN-VIEW SHOW
  const pageContainerRef = React.useRef(null);
  const isInView = useInView(pageContainerRef, { once: false, amount: 0.1 });
  const inViewControls = useAnimation();
  const inViewVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 5,
    },
  };
  React.useEffect(() => {
    if (isInView) {
      inViewControls.start("visible");
    } else if (!isInView) {
      inViewControls.start("hidden");
    }
  }, [inViewControls, isInView]);

  return (
    <div className={`${classes} flex flex-col justify-between h-full w-full`}>
      {/* ---- IMAGE ----*/}
      <LinkWork
        a={work.artist.slug}
        b={work.title}
        classes={"relative h-full flex flex-col justify-center items-center"}
      >
        <motion.div
          ref={pageContainerRef}
          animate={inViewControls}
          initial={"hidden"}
          variants={inViewVariants}
          transition={{ type: "easeOut", duration: 1 }}
        >
          <GatsbyImage
            image={workHerolImage}
            alt={""}
            className="h-full"
            // imgClassName={"w-[700px]"}
            objectFit={"inside"}
            objectPosition={"center center"}
          />
        </motion.div>
        <OverlayImage />
      </LinkWork>

      <div>
        {/* ---- ARTISTS NAME ----*/}
        {!location.pathname.includes((slug && slug) || work.artist.slug) ? (
          <LinkIcon>
            <LinkArtist a={slug ? slug : work.artist.slug}>
              <div className="mt-xl capitalize">
                {artist.firstName} {artist.lastName}
              </div>
            </LinkArtist>
          </LinkIcon>
        ) : (
          <div className="capitalize opacity-50 mt-xl">
            {artist.firstName} {artist.lastName}{" "}
          </div>
        )}
        {/* ---- WORK NAME ----*/}
        <LinkIcon>
          <LinkWork a={slug ? slug : work.artist.slug} b={work.title}>
            <div className="text-sm capitalize mt-xs">
              {title}, {year}
            </div>
          </LinkWork>
        </LinkIcon>
        {/* ---- MEDIUM ----*/}
        {isMedium ? (
          /* --- Work Medium --- */
          <div className="flex mt-sm">
            {mediums.map((medium, index) => {
              return (
                <LinkType
                  slug={"medium"}
                  type={medium.mediumType}
                  key={index}
                  isMedium={true}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
