import React from "react";
import { Link } from "gatsby";
import { LinkIcon } from "../shared/links";
import { responsiveTextBasic } from "./styles";
export const ArtistsWorkBtn = ({ location, classes }) => {
  return (
    <div className={`${classes} flex h-fit bg-yellow-50 w-full`}>
      {location.pathname.includes("/works") ? (
        <Link className={`mr-10 `} to={`/artists`}>
          <LinkIcon classes={responsiveTextBasic}> All Artists </LinkIcon>
        </Link>
      ) : (
        <div className={`opacity-50 mr-10 ${responsiveTextBasic}`}>
          All Artists
        </div>
      )}
      {!location.pathname.includes("/artists/works") ? (
        <Link className={`mr-10 `} to={`/artists/works`}>
          <LinkIcon classes={responsiveTextBasic}>All Works</LinkIcon>
        </Link>
      ) : (
        <div className="opacity-50 mr-10">
          <div className={`opacity-50 mr-10 ${responsiveTextBasic}`}>
            All Works
          </div>
          {/* <LinkIcon>All Works</LinkIcon> */}
        </div>
      )}
    </div>
  );
};
