import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/shared/layout";
import { Seo } from "../../../components/shared/seo";
import AllWorksNavbar from "../../../components/works/allWorksNavbar";
import { WorkGridItem } from "../../../components/shared/workGridItem";
import { gridStyle } from "../../../utils/gridStyles";

const AllWorksPage = (props) => {
  // --->  Data <--- //
  const works = props.data.allStrapiWork.nodes;
  // Filtered DATA
  const [dataFiltered, setDataFiltered] = React.useState(works);
  // SELECTED FILTER TYPE
  const [filterSelected, setFilterSelected] = React.useState("All Mediums");

  //  ---> RENDER <--- //
  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      {/* --------- Artist/Work NAVBAR ---------  */}
      <AllWorksNavbar
        location={props.location}
        works={works}
        setDataFiltered={setDataFiltered}
        setFilterSelected={setFilterSelected}
      />

      {/* ----- Artists Grid ----- */}
      <div className={`${gridStyle}`}>
        {/* ------------USE HERE EITHER WORK CONST WITH ORIGINAL DATA OR FILTERED DATA  */}
        {/* {work.map((work, index) => { */}
        {dataFiltered.map((work, index) => {
          return (
            <WorkGridItem
              key={index}
              work={work}
              classes={""}
              isMedium={true}
              location={props.location}
            />
          );
        })}
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ArtistsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiWork(sort: { fields: artist___lastName }) {
      nodes {
        collection {
          collectionName
        }
        mediums {
          mediumType
        }
        title
        year
        artist {
          firstName
          lastName
          slug
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  #aspectRatio: 1
                  #width: 700
                  height: 700
                  quality: 100
                  #layout: FULL_WIDTH
                  #transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default AllWorksPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Artists"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
