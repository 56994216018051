// export const GetMediums = (unfilteredMediums) => {
//   let mediumsFiltered = unfilteredMediums.map((unfilteredMedium) => {
//     return unfilteredMedium.mediums.map((medium) => {
//       return medium.mediumType;
//     });
//   });
//   // - remove duplicate
//   let uniqueMediums = new Set(mediumsFiltered);
//   //   - Array from a Set (need to transfrom uniqueMediums to array instead of set)
//   let mediumsFlatArray = Array.from(uniqueMediums);
//   mediumsFiltered = ["All Mediums", ...new Set(mediumsFlatArray.flat())];
//   return mediumsFiltered;
// };

// export const GetArtists = (unfilteredArtists) => {
//   let artistsFiltered = unfilteredArtists.map((unfilteredArtist) => {
//     return (
//       unfilteredArtist.artist.firstName + " " + unfilteredArtist.artist.lastName
//     );
//   });
//   // - remove duplicate
//   let uniqueArtists = new Set(artistsFiltered);
//   //   - Array from a Set (need to transfrom uniqueMediums to array instead of set)
//   let artistsFlatArray = Array.from(uniqueArtists);
//   artistsFiltered = ["All Artists", ...new Set(artistsFlatArray.flat())];
//   return artistsFiltered;
// };

// export const GetCollections = (unfilteredCollections) => {
//   let collectionsFiltered = unfilteredCollections.map(
//     (unfilteredCollection) => {
//       return (
//         unfilteredCollection.collection &&
//         unfilteredCollection.collection.collectionName
//       );
//     }
//   );
//   // - remove duplicate
//   let uniqueCollections = new Set(collectionsFiltered);
//   //   - Array from a Set (need to transfrom uniqueMediums to array instead of set)
//   let collectionsFlatArray = Array.from(uniqueCollections);
//   collectionsFiltered = [
//     "All Collections",
//     ...new Set(collectionsFlatArray.flat()),
//   ];
//   return collectionsFiltered;
// };

// export const GetProjectType = (unfilteredProjectType) => {
//   let projectsFiltered = unfilteredProjectType.map((unfilteredProjectType) => {
//     return unfilteredProjectType.projectType.projectType;
//   });
//   // - remove duplicate
//   let uniqueProjects = new Set(projectsFiltered);
//   //   - Array from a Set (need to transfrom uniqueMediums to array instead of set)
//   let projectsFlatArray = Array.from(uniqueProjects);
//   projectsFiltered = [
//     "All Project Types",
//     ...new Set(projectsFlatArray.flat()),
//   ];
//   return projectsFiltered;
// };

// DATA FORMATS
const formatArtists = (unfilteredData) =>
  unfilteredData.artist.firstName + " " + unfilteredData.artist.lastName;
const formatMediums = (unfilteredData) =>
  unfilteredData.mediums.map((medium) => {
    return medium.mediumType;
  });
const formatCollection = (unfilteredData) =>
  unfilteredData.collection && unfilteredData.collection.collectionName;
const formatProjectType = (unfilteredData) =>
  unfilteredData.projectType.projectType;

// DATA RECONSTRUCTION
export const GetFilterItems = (unfilteredData, filterName) => {
  let filteredData = unfilteredData.map((unfilteredData) => {
    return filterName === "Mediums"
      ? formatMediums(unfilteredData)
      : filterName === "Artists"
      ? formatArtists(unfilteredData)
      : filterName === "Collections"
      ? formatCollection(unfilteredData)
      : filterName === "Project Types" && formatProjectType(unfilteredData);
  });
  // - remove duplicate
  let uniqueItems = new Set(filteredData);
  //   - Array from a Set (need to transfrom uniqueMediums to array instead of set)
  let FlatArray = Array.from(uniqueItems);
  filteredData = [`All ${filterName}`, ...new Set(FlatArray.flat())];
  return filteredData;
};
