const formatMediums = (itemSelect, filter) =>
  itemSelect.mediums.find(({ mediumType }) => filter === mediumType);
const formatArtists = (itemSelect, filter) =>
  itemSelect.artist.firstName + " " + itemSelect.artist.lastName === filter;
const formatCollection = (itemSelect, filter) =>
  itemSelect.collection && itemSelect.collection.collectionName === filter;
const formatProjectType = (itemSelect, filter) =>
  itemSelect.projectType.projectType.toLowerCase() === filter.toLowerCase();

export const handleType = (
  filter,
  allData,
  typeName,
  setDataFiltered,
  setFilterSelected
) => {
  if (filter === "All" + " " + typeName) {
    setDataFiltered([...allData]);
    setFilterSelected(filter);
  } else {
    setDataFiltered([
      ...allData.filter((itemSelect) =>
        // interchnagable:

        typeName === "Mediums"
          ? formatMediums(itemSelect, filter)
          : typeName === "Artists"
          ? formatArtists(itemSelect, filter)
          : typeName === "Collections"
          ? formatCollection(itemSelect, filter)
          : typeName === "Project Types" &&
            formatProjectType(itemSelect, filter)
      ),
    ]);
    setFilterSelected(filter);
  }
};
