import * as React from "react";
import { GetFilterItems } from "../../utils/filterData";
import { ArtistsWorkBtn } from "../shared/artistsWorksBtn";
import { CloseIcon, FilterIcon } from "../../images/icons";
import { LinkIcon } from "../shared/links";
import { motion, AnimatePresence } from "framer-motion";
import { handleType } from "../../utils/filterHandler";
import {
  responsiveTextBasic,
  responsiveIconsBasic,
  layoutFilterHeight,
  stickyNav,
} from "../shared/styles";
const AllWorksNavbar = ({
  works,
  setDataFiltered,
  setFilterSelected,
  location,
}) => {
  const [isFilter, setIsFilter] = React.useState(false);
  const handleFilter = () => {
    setIsFilter(!isFilter);
  };

  // FILTER ------ //
  const [allWorks, setAllWorks] = React.useState(works);
  const mediumsData = GetFilterItems(works, "Mediums");
  const artistsData = GetFilterItems(works, "Artists");
  const collectionsData = GetFilterItems(works, "Collections");

  return (
    /* --------- Artist/Work NAVBAR ---------  */
    <div
      className={`relative ${layoutFilterHeight} mb-xl z-[29] bg-yellow-50 ${stickyNav}`}
    >
      <AnimatePresence initial={false}>
        {!isFilter ? (
          <motion.div
            key={isFilter}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="absolute w-full flex justify-between md:items-start xs:items-center"
          >
            <ArtistsWorkBtn location={location} classes={responsiveTextBasic} />

            <button onClick={() => handleFilter()}>
              <LinkIcon>
                <div className="flex items-center">
                  <div className="text-sm mr-5">Filter</div>
                  <FilterIcon classes={responsiveIconsBasic} />
                </div>
              </LinkIcon>
            </button>
          </motion.div>
        ) : (
          /* filter button  */

          <motion.div
            key={isFilter}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={`absolute w-full z-[3] bg-yellow-50 pb-md ${responsiveTextBasic}`}
          >
            <div className="flex w-full gap-x-sm ">
              {/* Flex Mediums */}
              <div className="flex order-first grow">
                {/* MEDIUMS */}
                <div className="w-full">
                  <div className="mb-5 opacity-50">Mediums</div>

                  {mediumsData.map((medium, index) => {
                    return (
                      <div className="" key={index}>
                        <button
                          className={`mr-5 ${responsiveTextBasic}`}
                          type="button"
                          onClick={() =>
                            handleType(
                              medium,
                              allWorks,
                              "Mediums",
                              setDataFiltered,
                              setFilterSelected
                            )
                          }
                        >
                          <LinkIcon>{medium}</LinkIcon>
                        </button>
                      </div>
                    );
                  })}
                </div>
                {/* /* ARTISTS */}
                <div className="w-full">
                  <div className="mb-5 opacity-50">Name</div>

                  {artistsData.map((artist, index) => {
                    return (
                      <div className="" key={index}>
                        <button
                          className={`mr-5 ${responsiveTextBasic}`}
                          type="button"
                          onClick={() =>
                            handleType(
                              artist,
                              allWorks,
                              "Artists",
                              setDataFiltered,
                              setFilterSelected
                            )
                          }
                        >
                          <LinkIcon>{artist}</LinkIcon>
                        </button>
                      </div>
                    );
                  })}
                </div>
                {/* /* COLLECTIONS */}
                <div className="w-full">
                  <div className="mb-5 opacity-50"> Collection</div>

                  {collectionsData.map((collection, index) => {
                    return (
                      <div className="" key={index}>
                        <button
                          className={`mr-5 ${responsiveTextBasic}`}
                          type="button"
                          onClick={() =>
                            handleType(
                              collection,
                              allWorks,
                              "Collections",
                              setDataFiltered,
                              setFilterSelected
                            )
                          }
                        >
                          <LinkIcon>{collection}</LinkIcon>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="order-last">
                <button
                  onClick={() => handleFilter()}
                  className={"inline-block"}
                >
                  <LinkIcon>
                    <CloseIcon classes={"w-[1rem]"} />
                  </LinkIcon>
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AllWorksNavbar;
